import moment from "moment";
import "moment/locale/da";
import { Component } from "react";
import { animated, Transition } from "react-spring/renderprops.cjs";
import { Order } from "../../Generated/graphql";
import { Icon } from "../Icon/Icon";
import { OrderComment } from "../OrderComment/OrderComment";
import { InactiveOrderDetails } from "./InactiveOrderDetails/InactiveOrderDetails";
import "./InactiveOrders.css";

moment.locale("da");

interface IInactiveOrdersProps {
  order: Order;
  shouldAnimate?: boolean;
}

interface IInactiveOrdersState {
  showComments: boolean;
  windowWidth?: number;
  expanded: boolean;
}

export class InactiveOrders extends Component<
  IInactiveOrdersProps,
  IInactiveOrdersState
> {
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  state = {
    windowWidth: 768,
    expanded: false,
    showComments: false,
  };

  render() {
    const fullBorder = "InactiveOrder InactiveOrders__fullBorder";
    const halfBorder = "InactiveOrder InactiveOrders__halfBorder";

    const iconFullBorder = "InactiveOrders__icon InactiveOrders__iconFull";
    const iconHalfBorder = "InactiveOrders__icon InactiveOrders__iconHalf";
    const { order } = this.props;
    const isHandledOrder = order.acknowledged;
    const isBackOrder = order.backorder;
    const isInvoiced = order.invoiced;
    return (
      <Transition
        items={order}
        from={{ opacity: 0, transform: "rotateX(90deg)" }}
        enter={{ opacity: 1, transform: "rotateX(0deg)" }}
        leave={{ opacity: 0, transform: "rotateX(-90deg)" }}
      >
        {(show) =>
          show &&
          ((props) => (
            <animated.div
              className="InactiveOrders"
              onClick={(e) => {
                if (
                  e.currentTarget.id !== "ActiveOrder__commentclicker" &&
                  this.state.showComments !== true
                ) {
                  this.setState({
                    expanded: !this.state.expanded,
                  });
                }
              }}
              style={this.props.shouldAnimate ? props : {}}
            >
              <div className={this.state.expanded ? halfBorder : fullBorder}>
                <div
                  className={
                    this.state.expanded ? iconHalfBorder : iconFullBorder
                  }
                >
                  {order.completed ? (
                    <Icon
                      name="checkmarkTBlue"
                      width={20}
                      className="Icon__center"
                    />
                  ) : (
                    <Icon
                      name="cartListview"
                      width={28}
                      className="Icon__center"
                    />
                  )}
                </div>
                <div className="InactiveOrders__order">
                  <div className="InactiveOrders__firstPart">
                    <div className="InactiveOrders__order-number">
                      Ordre {order.id}
                    </div>
                    {order.deliveryId ? (  <div className="InactiveOrders__delivery-number">
                      Leverance {order.deliveryId}
                    </div>) : (<></>)}                  
                    {this.state.windowWidth > 1024 ? (
                      <div className="ActiveOrders__order-date">
                        Bestilt{" "}
                        {moment(order.orderDate, "YYYYMMDD").format(
                          "DD[.] MMMM YYYY"
                        )}
                      </div>
                    ) : (
                      <div className="ActiveOrders__order-date">
                        {moment(order.orderDate, "YYYYMMDD").format(
                          "DD[.] MMM[.] YYYY"
                        )}
                      </div>
                    )}
                  </div>
                  <div className="InactiveOrders__secondPart">
                    <div className="InactiveOrders__order-amount">
                      {order.orderLines.length >= 2
                        ? order.orderLines.length + " varer i alt"
                        : order.orderLines.length + " vare i alt"}
                    </div>
                    <div className="InactiveOrders__order-from">
                      Bestilt fra{" "}
                      {order.orderedFrom === 2
                        ? "lagerstyring"
                        : order.orderedFrom === 1
                        ? "webshop"
                        : "internt salg"}
                    </div>
                  </div>
                  {this.state.windowWidth > 1023 && !order.completed && (
                    <div className="IncomingOrders__order-status">
                      <div className="IncomingOrders__order-status-icon">
                        <Icon
                          name={
                            !isHandledOrder ? 
                            "cartListview" 
                            : isBackOrder ?
                            "orderTruck" 
                            : isInvoiced ?
                            "checkmarkGreen" 
                            : "orderTruck"
                          }
                          width={17.3}
                        />
                      </div>
                      <div className="IncomingOrders__order-status-text">
                        {                 
                          !isHandledOrder ?
                          "Afventer behandling"
                          : isBackOrder ?
                          "Restordre"
                          : isInvoiced ?  
                          "Afsluttet"
                          : "Ordren behandles"
                       }
                      </div>
                    </div>
                  )}
                  {order &&
                    order.comments &&
                    Array.isArray(order.comments) &&
                    order.comments.length > 0 && (
                      <div
                        className="IncomingOrders__order-comments"
                        id="ActiveOrder__commentclicker"
                        onClick={() => {
                          this.setState({
                            showComments: !this.state.showComments,
                          });
                        }}
                      >
                        <OrderComment
                          comments={order.comments}
                          displayComments={this.state.showComments}
                          orderId={order.id}
                          completed={order.completed}
                        />
                      </div>
                    )}
                  <div className="InactiveOrders__see-order u-cursor-pointer">
                    <div className="InactiveOrders__see-order-text">
                      Se bestilling
                    </div>
                    <div className="InactiveOrders__see-order-icon">
                      <Icon name="arrowDown" width={11} />
                    </div>
                  </div>
                </div>
              </div>
              <Transition
                native
                items={this.state.expanded}
                from={{ height: 0, overflow: "hidden" }}
                enter={{ height: "auto" }}
                leave={{ height: 0, overflow: "hidden" }}
                config={{
                  tension: 280,
                  friction: 40,
                  precision: 0.1,
                  velocity: 50,
                }}
              >
                {(show) =>
                  show &&
                  ((props) => (
                    <animated.div
                      style={{ ...props, position: "relative", zIndex: 1 }}
                    >
                      <InactiveOrderDetails
                        windowWidth={this.state.windowWidth}
                        items={order.orderLines}
                        orderStatus={order.completed}
                        orderIcon={order.orderLines[0].taken}
                      />
                    </animated.div>
                  ))
                }
              </Transition>
            </animated.div>
          ))
        }
      </Transition>
    );
  }
}
