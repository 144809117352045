import { compact, orderBy } from "lodash";
import moment from "moment";
import "moment/locale/da";
import { Fragment, useMemo, useState } from "react";
import { ActiveOrders } from "../../Components/ActiveOrders/ActiveOrders";
import { BoxLoader } from "../../Components/BoxLoader/BoxLoader";
import { Button } from "../../Components/Button/Button";
import { InactiveOrders } from "../../Components/InactiveOrders/InactiveOrders";
import { useInventoryQuery, useOrdersQuery } from "../../Generated/graphql";
import "./Receive.css";

moment.locale("da");

export const Receive = () => {
  const [showEarlier, setShowEarlier] = useState(false);
  const [scrollValue, setScrollValue] = useState(false);

  const { data: stockData } = useInventoryQuery();

  const { data: ordersData, loading: orderLoading } = useOrdersQuery({
    // Equates to every 2 minutes
    pollInterval: 120000,
  });

  const readyOrders = useMemo(() => {
    if (!ordersData) {
      return [];
    }
  
    return orderBy(
      compact(
        ordersData.orders.map((order) => {
          if (
            order.picked &&
            !order.completed &&
            !order.backorder &&
            order.orderLines &&
            order.orderLines.length > 0 &&
            order.acknowledged
          ) {
            return order;
          } else {
            return null;
          }
        })
      ),
      ["orderDate", "id"],
      ["desc", "desc"]
    );
  }, [ordersData]);
  
  const incomingOrders = useMemo(() => {
    if (!ordersData) {
      return [];
    }
  
    return orderBy(
      compact(
        ordersData.orders.map((order) => {
          if (
            !order.completed &&
            order.orderLines &&
            order.orderLines.length > 0 &&
            (order.acknowledged || order.backorder) &&
            !readyOrders.some((readyOrder) => readyOrder.id === order.id)
          ) {
            return order;
          } else {
            return null;
          }
        })
      ),
      ["orderDate", "id"],
      ["desc", "desc"]
    )
  }, [ordersData, readyOrders]);
  
  const otherOrders = useMemo(() => {
    if (!ordersData) {
      return [];
    }
  
    return orderBy(
      compact(
        ordersData.orders.map((order) => {
          if (
            order.completed &&
            order.picked 
            // &&
            // order.orderLines &&
            // order.orderLines.length > 0
          ) {
            return order;
          } else {
            return null;
          }
        })
      ),
      ["orderDate", "id"],
      ["desc", "desc"]
    );
  }, [ordersData]);

  const lastestThreeCompletedOrders = otherOrders.filter(
    (x, index) => index <= 2
  );

  const theRestOfCompletedOrders = otherOrders
    .filter((x, index) => index > 2)
    .slice(0, 20);

  return (
    <div className="Receive">
      <div className="Receive__header">
        <div className="Receive__title">Modtagelse af varer</div>
      </div>
      <div className="Receive__dividerbar" />
      <div
        className="Receive__export"
        style={{
          boxShadow: scrollValue ? "0 7px 7px 0 rgba(0,0,0,0.25)" : undefined,
          height: scrollValue ? "7px" : "0px",
        }}
      ></div>
      <div
        className="Receive__content"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
        onScroll={(e) => {
          if (!scrollValue && e.currentTarget.scrollTop > 8) {
            setScrollValue(true);
          } else if (scrollValue && e.currentTarget.scrollTop <= 5) {
            setScrollValue(false);
          }
        }}
      >
        <div className="Receive__subtitle Receive__subtitle-active">
          Igangværende bestillinger
        </div>
        {readyOrders.length === 0 &&
          incomingOrders.length === 0 &&
          otherOrders.length > 0 && (
            <div className="Receive__noorder">
              <div className="Receive__no-current-orders">
                Ingen igangværende bestillinger
              </div>
            </div>
          )}

        {readyOrders.map((order) => {
          return order !== null ? (
            <Fragment key={order.id}>
              <ActiveOrders
                order={order}
                inventory={stockData?.inventory ?? []}
              />
            </Fragment>
          ) : null;
        })}

        {incomingOrders.map((order) => {
          return order !== null ? (
            <InactiveOrders key={order.id} order={order} />
          ) : null;
        })}

        {otherOrders && otherOrders.length > 0 && (
          <div className="Receive__completedOrders">
            <div className="Receive__completedOrders__title">
              <div className="Receive__completedOrders__title-text">
                Tidligere bestillinger
              </div>
            </div>
            {lastestThreeCompletedOrders.map((order) => {
              return order !== null && order.orderLines.length > 0 ? (
                <InactiveOrders
                  key={order.id}
                  order={order}
                  shouldAnimate={true}
                />
              ) : null;
            })}

            {otherOrders && otherOrders.length > 0 && (
              <div className="Receive__show-earlier">
                <Button
                  width="229px"
                  buttonStyle="showMore"
                  labelStyle="Button__showMoreOrders"
                  click={() => setShowEarlier(!showEarlier)}
                >
                  {showEarlier ? "Skjul" : "Vis flere"}
                </Button>
              </div>
            )}

            {showEarlier &&
              theRestOfCompletedOrders.map((order, index) => {
                return order !== null && order.orderLines.length > 0 ? (
                  <InactiveOrders
                    key={order.id}
                    order={order}
                    shouldAnimate={false}
                  />
                ) : null;
              })}
          </div>
        )}

        {orderLoading && <BoxLoader />}

        {!orderLoading &&
          readyOrders.length === 0 &&
          incomingOrders.length === 0 &&
          otherOrders.length === 0 && (
            <div className="Receive__no-orders">
              Ordrer kan ikke vises i øjeblikket
            </div>
          )}
      </div>
    </div>
  );
};
